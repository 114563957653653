import React from "react";
import { Layout, Seo } from "components/common";


import GridModalImages from "components/GridModalImages";

import { MainContent } from "components/MainContent";

import { elektroinstallationenContent } from "pagesData/elektroinstallationen";
import { IntroRando } from "components/IntroRandom";

const Elektro = () => (
  <Layout>
    <Seo />
    <IntroRando  {...elektroinstallationenContent} />

    {/* <Intro {...elektroinstallationenContent} /> */}
    <MainContent content={elektroinstallationenContent.mainContent} />
    <GridModalImages images={elektroinstallationenContent.modalImages} />

  </Layout>
);

export default Elektro;
