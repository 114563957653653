// elektroinstallationen page - elektroinstallationen.js

import netz from "assets/bilder/elektro/KV_AD_5177_rgb.jpg";
import netz2 from "assets/bilder/elektro/KV_AD_5231_rgb.jpg";
import netz3 from "assets/bilder/elektro/KV_AD_8915_rgb.jpg";

import eins from "assets/bilder/elektro/AD_8860_rgb.jpg";
import zwei from "assets/bilder/elektro/AD_8904_rgb.jpg";
import drei from "assets/bilder/elektro/AD_5252_rgb.jpg";
import vier from "assets/bilder/elektro/AD_5227_rgb.jpg";
import fu from "assets/bilder/elektro/AD_9459_rgb.jpg";
import six from "assets/bilder/elektro/AD_9551_rgb.jpg";


export const elektroinstallationenContent = {
    h2: "Elektro Installationen",
    bgs: [
     netz,netz2,netz3
    ],
    subtitle: "Licht, Steckdose, Smart Home – alles aus einer Hand.",
    // background: netz,
    mainContent: `
    <p>
    Allgemeine Arbeiten und Installationen rund um den Strom sind unser eigentliches Kerngeschäft. 
    <br />    <br />

    Unsere hohen Ansprüche an Qualität und Zuverlässigkeit stellen wir unseren Auftraggebern jederzeit zur Verfügung.
     Wir Arbeiten stets Lösungsorientiert und im Interesse unserer geschätzten Kundschaft - ob es ein Leuchtmittel in einer Designerlampe zu tauschen gibt, eine Instandstellung älterer Anlagen ansteht, Ihre SmartHome Funktionen nicht mehr
     flüssig laufen oder eine Mängelbehebung nach einer periodischen Kontrolle. 

    Wenn Elektro Huwiler im Hause war, ist es mit den Problemen vorbei.
    <br></br>

    Wir nehmen Sie mit Ihren Anliegen ernst und tun unser Bestes,
     den Wunschzustand schnellstmöglich zu generieren. 
     Unser Sorglos-Service bieten wir Ihnen zu den üblichen Geschäftszeiten, 
     wie auch als Pikettdienst 7/24h an, sollte ein Notfall eintreffen.
    Damit stehen sie mit uns als Elektro-Dienstleister nie im Dunkeln.
    
    <br></br>
    Für weitere Informationen stehen wir Ihnen jederzeit über unser
    <a href="/kontakt?anliegen=elektroinstallationen">Kontaktformular</a> zur Verfügung,
    schreiben Sie uns ein <a href="mailto:info@elektro-huwiler.ch">E-Mail</a> oder wir nehmen Ihren Anruf gerne auf
     <a href="tel:0447776544">044 777 65 44</a> entgegen.
    <br></br>
    </p>
    `,
    modalImages: [
        eins,
        zwei,
        drei,
        vier,
        fu, six
    ],
};